import { defineStore, getActivePinia } from "pinia";
import { fetchWrapper } from "../_helpers/fetch-wrapper";
import { useNotification } from "@kyvg/vue3-notification";
import { computed } from "vue";
import { useAuthStore } from "./auth.store";
const notification = useNotification();

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    users: [],
    totalRecords: 0,
    user: {},
    usersAll: [],
    loading: false,
    getAllUsersLoading:false,
    responseStatus: 100,
    getAllUsersPagePagination:{
      page: 1,
      rowsPerPage: 25,
      search: '',
      sortBy: 'created_ts',
      sortType: 'desc',
    },
    isDeleteing: false
  }), 
  actions: {
    async resetAllPiniaStores  () {
      const activeStores = Object.keys(getActivePinia().state.value);
      activeStores.forEach((store) => {
        if (store != "isLoading") {
          getActivePinia()._s.get(store).$reset();
        }
      });
    },
    async getUsers() {
      this.loading = true;
      await fetchWrapper
        .get("get-users?page=" + this.getAllUsersPagePagination.page +
         "&limit=" + this.getAllUsersPagePagination.rowsPerPage +
          "&search=" + this.getAllUsersPagePagination.search +
          "&sort_by=" + this.getAllUsersPagePagination.sortBy +
           "&order_by=" + this.getAllUsersPagePagination.sortType)
        .then((res) => { 
          if(res){
            this.users = updateRowRecords(res.data) 
            this.totalRecords = res.total_records;
          }
        });
      this.loading = false;
    },
    async getAllUsers() {
      this.getAllUsersLoading = true;
      try {
        const res = await fetchWrapper.get("get-users?page=1&limit=-1&search=&sort_by=created_ts&order_by=desc");
        if (res && res.data) {
          this.usersAll = updateRowRecords(res.data);
        }
      } catch (error) {
        console.error("Failed to fetch users:", error);
      } finally {
        this.getAllUsersLoading = false;
      }
    },
    async getUser(id) {
      this.loading = true;
      await fetchWrapper.get("users/" + id).then((res) => {
        this.users = res.data;
        this.totalRecords = res.total_records;
      });
      this.loading = false;
    },
    async storeUser(user) {
      this.loading = true;
      const res = await fetchWrapper.post("add-users", { data: [user] }); 
      const authStore = useAuthStore();
      const userRole = computed(() => authStore.$state.user?.role);
      const isSuperAdmin = userRole.value === 'superadmin';
      // console.log(res.message , "phone number");
      if (!res.error) {
        this.getUsers();
        if(isSuperAdmin){
          this.getAllUsers();
        }
        this.errorResponse = res.error;
        notification.notify({
          title: "Success!",
          text: res.message, // Use the success message from the API response
          type: "success",
        });
      } else { 
        this.errorResponse = res.error;
        notification.notify({
          title: "Error!",
          text: res.message,
          type: "error",
        });
      }
      this.loading = false;
    },

    async updateUser(newUser, oldUser) {
      this.loading = true;
      let array = [];
      array.push(oldUser);
      array.push(newUser);
      const res = await fetchWrapper.post("edit-user", { data: [array] });
      const authStore = useAuthStore();
      const userRole = computed(() => authStore.$state.user?.role);
      const isSuperAdmin = userRole.value === 'superadmin';
      if (!res.error) {
        this.getUsers();
        if(isSuperAdmin){
          this.getAllUsers();
        }
        this.errorResponse = res.error;
        notification.notify({
          title: "Success!",
          text: res.message, // Use the success message from the API response
          type: "success",
        });
      } else { 
        this.errorResponse = res.error;
        notification.notify({
          title: "Error!",
          text: res.message,
          type: "error",
        });
      }
      this.loading = false;
    },
    async deleteUser(user) {
      this.loading = true;
      await fetchWrapper.post("remove-users", { data: user }).then((res) => {
        const authStore = useAuthStore();
        const userRole = computed(() => authStore.$state.user?.role);
        const isSuperAdmin = userRole.value === 'superadmin';
        if(res){
          this.getUsers();
          if(isSuperAdmin){
            this.getAllUsers();
          }
          this.loading = false;
          console.log(this.loading , "remove user");
        }
      });
      
    },
    async deleteAllUser() {
      this.loading = true;
      this.isDeleteing = true;
      await fetchWrapper.get("remove-all-users").then((response) => {
        const authStore = useAuthStore();
        const userRole = computed(() => authStore.$state.user?.role);
        const isSuperAdmin = userRole.value === 'superadmin';
        if (!response.error) {
          this.getUsers();
          if(isSuperAdmin){
            this.getAllUsers();
          }
          this.loading = false;
          this.isDeleteing = false;
        }
      });
    },
  },
});

const updateRowRecords = (data) => {
  if (Array.isArray(data)) { 
    const record = [];
    data.map((row) =>{
      record.push(convertEmptyToDash(row));
    })
    return record;
  } else if (typeof data === 'object') {
    return convertEmptyToDash(data)
  }
}

// const convertEmptyToDash = (data) => { 
//   // Process an object
//   const convertedObject = {};
//   for (let key in data) {
//     if (data.hasOwnProperty(key)) {
//       convertedObject[key] = data[key] === 'EMPTY' ? '-' : data[key];
     
//     }
//   } 
//   return convertedObject;
// };
const convertEmptyToDash = (data) => {
  const convertedObject = {};

  // all conditions for Emails
  if (data.email_1 !== 'EMPTY' || data.email_2 !== 'EMPTY' || data.email_3 !== 'EMPTY') {
    if (data.email_1 !== 'EMPTY') {
      convertedObject.email_1 = data.email_1;
    } else {
      convertedObject.email_1 = '-';
    }

    if (data.email_2 !== 'EMPTY') {
      convertedObject.email_2 = data.email_2;
    } else if (data.email_1 !== 'EMPTY' && data.email_3 === 'EMPTY') {
      convertedObject.email_2 = '';
    } else {
      convertedObject.email_2 = '-';
    }

    if (data.email_3 !== 'EMPTY') {
      convertedObject.email_3 = data.email_3;
    } else if (data.email_1 === 'EMPTY' && data.email_2 !== 'EMPTY') {
      convertedObject.email_3 = '';
    } else if (data.email_1 !== 'EMPTY' && data.email_2 !== 'EMPTY') {
      convertedObject.email_3 = '';
    } else if (data.email_1 !== 'EMPTY' && data.email_2 === 'EMPTY') {
      convertedObject.email_3 = '';
    } else {
      convertedObject.email_3 = '-';
    }
  } else {
    convertedObject.email_1 = '-';
    convertedObject.email_2 = '';
    convertedObject.email_3 = '';
  }

  // all conditions for phone
  if (data.phone_1 !== 'EMPTY' || data.phone_2 !== 'EMPTY' || data.phone_3 !== 'EMPTY') {
    if (data.phone_1 !== 'EMPTY') {
      convertedObject.phone_1 = data.phone_1;
    } else {
      convertedObject.phone_1 = '-';
    }

    if (data.phone_2 !== 'EMPTY') {
      convertedObject.phone_2 = data.phone_2;
    } else if (data.phone_1 !== 'EMPTY' && data.phone_3 === 'EMPTY') {
      convertedObject.phone_2 = '';
    } else {
      convertedObject.phone_2 = '-';
    }

    if (data.phone_3 !== 'EMPTY') {
      convertedObject.phone_3 = data.phone_3;
    } else if (data.phone_1 === 'EMPTY' && data.phone_2 !== 'EMPTY') {
      convertedObject.phone_3 = '';
    } else if (data.phone_1 !== 'EMPTY' && data.phone_2 !== 'EMPTY') {
      convertedObject.phone_3 = '';
    } else if (data.phone_1 !== 'EMPTY' && data.phone_2 === 'EMPTY') {
      convertedObject.phone_3 = '';
    } else {
      convertedObject.phone_3 = '-';
    }
  } else {
    convertedObject.phone_1 = '-';
    convertedObject.phone_2 = '';
    convertedObject.phone_3 = '';
  }
  // Similar logic for phone numbers can be added if needed

  // For other fields, process as before
  for (let key in data) {
    if (data.hasOwnProperty(key) && !key.startsWith('email_') && !key.startsWith('phone_')) {
      convertedObject[key] = data[key] === 'EMPTY' ? '-' : data[key];
    }
  }

  return convertedObject;
};


