import { useAuthStore } from "../stores/auth.store";
import { useNotification } from "@kyvg/vue3-notification";
import { useUserStore } from "@/stores/user.store";
import { useBettorStore } from "@/stores/bettor.store";
import { ref } from 'vue';
// Add a ref property to your component's data

const notification = useNotification();
const baseUrl = process.env.VUE_APP_NODE_ENV === 'local' ? process.env.VUE_APP_API_URL : `${window.location.origin}`;
export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};
let methodType = "";
function request(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    methodType = method;
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    try {
      return fetch(
        `${baseUrl}/${url}`,
        requestOptions
      ).then(handleResponse);
    } catch (error) {
      // Handle the error
      console.error('Error fetching data:', error.message);
    }
  };
}


// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const authStore = useAuthStore();
  const isLoggedIn = authStore.user;
  const isApiUrl = url;

  if (isLoggedIn && isApiUrl) {
    return {
      Authorization: `bearer ${authStore.user.access_token}`,
      "Api-token": process.env.VUE_APP_LIST_PROVIDER_API_TOKEN,
    };
  } else {
    authStore.logout();
  }
}


async function handleResponse(response) {
  try {
    const isJson = response.headers
      ?.get("content-type")
      ?.includes("application/json");
    const data = isJson ? await response.json() : null;

  // check for error response
  if (!response.ok) {
    const useBettor = useBettorStore();
    const { user, logout } = useAuthStore();
    if ([401].includes(response.status) && user) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      logout();
    }
    // Handle 400 Bad Request status code separately
    if ([400].includes(response.status)) {
      // Do something specific for 400 Bad Request, e.g., show a notification
      // You can choose to return some specific value or just resolve the promise
      useBettor.applicationStatus = true;
    }
    const error = (data && data.message) || response.status;
    throw new Error(error);
  }

  const useStore = useUserStore();  
  const { logout } = useAuthStore();
  const application_status = response.headers?.get("application-status")
  const responseStatus = application_status;
  if(useStore.responseStatus !== responseStatus){ 
    useStore.responseStatus = responseStatus; 
    if(useStore.responseStatus ===  200){ 
      logout(); 
    }
  }

    return data;
  } catch (error) { 
  }
}