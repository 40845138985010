import { defineStore, getActivePinia } from "pinia";
import { fetchWrapper } from "../_helpers/fetch-wrapper";
import { useAuthStore } from "./auth.store";
export const useBettorStore = defineStore({
  id: "bettor",
  state: () => ({
    isLoading: false,
    isPotentialLoading: false,
    isConfirmedLoading: false,
    isResolvedLoading: false,
    isArchiveLoading: false,
    items: [],
    allMatches: [],
    potentialMatches: [],
    completedMatches: [],
    resolvedMatches: [],
    archiveMatches: [],
    totalMatches: 0,
    totalPotentialMatches: 0,
    totalConfirmedMatches: 0,
    totalResolvedMatches: 0,
    totalArchiveMatches: 0,
    item: {},
    jurisdiction: [],
    leagues: [],
    contactInfo: {},
    notifications: {},
    applicationStatus: false,
    appStatusCause: "",
    getPotentialPagePagination: {
      page: 1,
      rowsPerPage: 25,
      search: "",
      sortBy: "when_match",
      sortType: "desc",
    },
    getConfirmedPagePagination: {
      page: 1,
      rowsPerPage: 25,
      search: "",
      sortBy: "when_match",
      sortType: "desc",
    },
    getResolvedPagePagination: {
      page: 1,
      rowsPerPage: 25,
      search: "",
      sortBy: "when_match",
      sortType: "desc",
    },
    getArchivedPagePagination: {
      page: 1,
      rowsPerPage: 25,
      search: "",
      sortBy: "when_match",
      sortType: "desc",
    },
  }),
  actions: {
    async resetSpecificObject() {
      this.getPotentialPagePagination = {
        page: 1,
        rowsPerPage: 25,
        search: "",
        sortBy: "when_match",
        sortType: "desc",
      };
      this.getConfirmedPagePagination = {
        page: 1,
        rowsPerPage: 25,
        search: "",
        sortBy: "when_match",
        sortType: "desc",
      };
      this.getResolvedPagePagination = {
        page: 1,
        rowsPerPage: 25,
        search: "",
        sortBy: "when_match",
        sortType: "desc",
      };
      this.getArchivedPagePagination = {
        page: 1,
        rowsPerPage: 25,
        search: "",
        sortBy: "when_match",
        sortType: "desc",
      };
    },
    async updateUserRoleManually(newRole) {
      await fetchWrapper.post("update-user-role", { user_role: newRole }).then((res) => {
        if(res){
          const authStore = useAuthStore();
          authStore.updateRoleInStore(newRole);
        }
      });
    },
    async getApplicationStatus() {
      await fetchWrapper
        .get("get-application-status")
        .then((res) => {
          if (res.status) {
            this.applicationStatus = true;
            this.appStatusCause = res.app_status_cause;
          } else if (this.applicationStatus && !res.status) {
            const authStore = useAuthStore();
            this.applicationStatus = false;
            $(".modal").modal("hide");
            authStore.logout();
          }
        })
        .catch((error) => {
          console.error("Error fetching application status:", error);
        });
    },
    async getAll() {
      this.isLoading = true;
      await fetchWrapper.get("get-checks").then((res) => {
        if (res) {
          this.items = res.get_checks;
        }
      });

      this.isLoading = false;
    },
    async getMatches(uuid) {
      this.isLoading = true;
      await fetchWrapper.get("get-matches?uuid=" + uuid).then((res) => {
        if (res) {
          this.allMatches = res.match_records;
          this.totalMatches = res.total_records;
        }
      });

      this.isLoading = false;
    },
    async getPotentialIncidents() {
      this.isPotentialLoading = true;
      await fetchWrapper
        .get(
          "get-unresolved-matches?unresolved_status=U&page=" +
            this.getPotentialPagePagination.page +
            "&limit=" +
            this.getPotentialPagePagination.rowsPerPage +
            "&search=" +
            this.getPotentialPagePagination.search +
            "&sort_by=" +
            this.getPotentialPagePagination.sortBy +
            "&order_by=" +
            this.getPotentialPagePagination.sortType
        )
        .then((res) => {
          if (res) {
            this.potentialMatches = organizeRecords(
              res.result_unresolved_matches
            );
            this.totalPotentialMatches = res.total_records;
          }
        });
      this.isPotentialLoading = false;
    },
    async getCompletedIncidents() {
      this.isConfirmedLoading = true;
      await fetchWrapper
        .get(
          "get-unresolved-matches?unresolved_status=C&page=" +
            this.getConfirmedPagePagination.page +
            "&limit=" +
            this.getConfirmedPagePagination.rowsPerPage +
            "&search=" +
            this.getConfirmedPagePagination.search +
            "&sort_by=" +
            this.getConfirmedPagePagination.sortBy +
            "&order_by=" +
            this.getConfirmedPagePagination.sortType
        )
        .then((res) => {
          if (res) {
            this.completedMatches = organizeRecords(
              res.result_unresolved_matches
            );
            this.totalConfirmedMatches = res.total_records;
          }
        });
      this.isConfirmedLoading = false;
    },
    async getResolvedIncidents() {
      this.isResolvedLoading = true;
      await fetchWrapper
        .get(
          "get-resolved-matches?page=" +
            this.getResolvedPagePagination.page +
            "&limit=" +
            this.getResolvedPagePagination.rowsPerPage +
            "&search=" +
            this.getResolvedPagePagination.search +
            "&sort_by=" +
            this.getResolvedPagePagination.sortBy +
            "&order_by=" +
            this.getResolvedPagePagination.sortType
        )
        .then((res) => {
          if (res) {
            this.resolvedMatches = organizeRecords(res.result_resolved_matches);
            this.totalResolvedMatches = res.total_records;
          }
        });
      this.isResolvedLoading = false;
    },
    async getArchiveIncidents() {
      this.isArchiveLoading = true;
      await fetchWrapper
        .get(
          "get-no-match-matches?page=" +
            this.getArchivedPagePagination.page +
            "&limit=" +
            this.getArchivedPagePagination.rowsPerPage +
            "&search=" +
            this.getArchivedPagePagination.search +
            "&sort_by=" +
            this.getArchivedPagePagination.sortBy +
            "&order_by=" +
            this.getArchivedPagePagination.sortType
        )
        .then((res) => {
          if (res) {
            this.archiveMatches = organizeRecords(res.result_no_match_matches);
            this.totalArchiveMatches = res.total_records;
          }
        });
      this.isArchiveLoading = false;
    },
    async getMatch(id) {
      this.isLoading = true;
      await fetchWrapper.post("get-match", { id: id }).then((res) => {
        if (res) {
          this.item = res.match_record;
        }
      });
      this.isLoading = false;
    },
    async updateNotStatus(ids) {
      this.isLoading = true;
      await fetchWrapper
        .post("update-notification-status", { ids: ids })
        .then((res) => {
          if (res) {
            this.getNotifications();
          }
        });
      this.isLoading = false;
    },
    async updateUnreadStatus(ids) {
      this.isLoading = true;
      await fetchWrapper
        .post("update-unread-notification-status", { ids: ids })
        .then((res) => {
          if (res) {
            this.getNotifications();
          }
        });
      this.isLoading = false;
    },
    async getContactInfo(unique_id) {
      this.isLoading = true;
      await fetchWrapper
        .post("matches-get-contact-info", { unique_id: unique_id })
        .then((res) => {
          if (res) {
            this.contactInfo = res.match_contact_message;
          }
        });
      this.isLoading = false;
    },
    async getNotifications() {
      await fetchWrapper.get("get-notifications").then((res) => {
        if (res) {
          this.notifications = res.notification_count;
        }
      });
    },
    async getJurisdiction() {
      await fetchWrapper.get("get-jurisdiction").then((res) => {
        if (res) {
          this.jurisdiction = res.data;
        }
      });
    },
    async getLeagues() {
      const response = await fetchWrapper.get("get-leagues");
      if (response) {
        const responseData = response.data;
        responseData.sort((a, b) =>
          a.display_name.localeCompare(b.display_name)
        );
        this.leagues = [];
        for (const element of responseData) {
          this.leagues.push({
            id: element.id,
            level: element.level,
            parent_id: element.parent_id,
            value: element.display_name,
            name: element.name,
            display: element.display_name,
          });
        }
      }
    },
    async updateMatchResolution(
      id,
      resolution_status,
      resolution_status_notes
    ) {
      await fetchWrapper
        .post("update-match-resolution", {
          id: parseInt(id),
          resolution_status: resolution_status,
          resolution_status_notes: resolution_status_notes,
        })
        .then((res) => {});
    },
    async updateMatchStatus(id, match_status, match_status_notes) {
      await fetchWrapper
        .post("update-match-status", {
          id: parseInt(id),
          match_status: match_status,
          match_status_notes: match_status_notes,
        })
        .then(() => {
          // this.item = res.result_match;
        });
    },
    async updatePassword(password, confirm_password) {
      await fetchWrapper.post("update-user-password", {
        password: password,
        confirm_password: confirm_password,
      });
    },
    async updateMatchStatusSilence(id, silent_mode) {
      await fetchWrapper.post("match/update-status-silenced", {
        id: parseInt(id),
        silent_mode: silent_mode,
      });
    },
    async updateCSVCheck() {
      await fetchWrapper.post("update-csv-acceptance", {
        csv_acceptance: true,
      });
      const authStore = useAuthStore();
      authStore.csvAccept = 1;
      localStorage.setItem("csvAccept", 1);
    },
  },
});
const organizeRecords = (records) => {
  const result = [];
  const recordMap = [];
  records.forEach((record) => {
    record.showChildren = false;
    const { group_id, ...rest } = record;
    if (typeof group_id !== "undefined") {
      if (result.some((record) => record.group_id === group_id)) {
        recordMap.push({ group_id, ...rest }); // Add record to recordMap
      } else {
        result.push({ group_id, ...rest, childrens: [] }); // Add showChildren property
      }
    }
  });
  result.forEach((parent) => {
    if (typeof parent.group_id !== "undefined") {
      parent.childrens = recordMap.filter(
        (record) => record.group_id === parent.group_id
      );
      parent.childrens.sort(
        (a, b) => new Date(b.when_match) - new Date(a.when_match)
      ); // Sort childrens array in descending order based on when_match
    }
  });
  console.log(result, "organize result");
  return result;
};
