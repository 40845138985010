<script setup>
import { Form, Field } from "vee-validate";
import { defineProps, computed, getCurrentInstance } from "vue";
import * as Yup from "yup";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { useAuthStore } from "../stores/auth.store";
import { useAuthTwoFA } from "../stores/authTwoFA.store";
const schema = Yup.object().shape({
  auth_code: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required("Authentication is required"),
});
const { emit } = getCurrentInstance();
const authStore = useAuthStore();
const authStoreTwoFA = useAuthTwoFA();
const authUserId = computed(() => authStore.$state.user.id);
const otpAuthURL = computed(() => authStore.$state.user.otp_auth_url);
const base32Key = computed(() => authStore.$state.user.otp_base32);
const checkOtpVerified = computed(() => authStore.$state.user?.otp_enabled);
const optAuthenticate = computed(() => authStore.$state?.opt_authenticate);
let props = defineProps({
  twoFactorAuthCheck: Boolean,
});
const register2FA = async (values) => {
  values = values.auth_code;
  await authStoreTwoFA.otpVerify(authUserId.value, values);
  if (checkOtpVerified.value === 1) {
    emit("cancel2FAModal");
  }
};
const cancel2FAModal = async (values) => {
  if (optAuthenticate.value){
    emit('cancel2FAModal');
  }
  else{
    authStore.logout();
  }
};
</script>
<template>
  <!-- Modal 2FA -->
  <div class="tokenInput" v-if="twoFactorAuthCheck">
    <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <Form
            id="form"
            @submit="register2FA"
            :validation-schema="schema"
            v-slot="{ errors, isSubmitting }"
          >
            <div class="row">
              <div class="col-12">
                <h4>Multi-Factor Authentication (MFA)</h4>
              </div>

              <div class="col-12">
                <div class="">
                  <h4 class="headingH4">
                    Activate Authenticator App
                  </h4>
                  <div class="orderdList text_sm">
                      To protect your account with multi-factor authentication, scan the QR code below with the authenticator app (<a href="https://support.google.com/accounts/answer/1066447" target="_blank">example</a>) of your choice. Then, input the verification code generated by the app below.
                  </div>
                  <div>
                    <h4 class="headingH4">Scan QR Code</h4>
                    <div class="d-flex justify-content-center">
                      <vue-qrcode ref="qrcode" :value="otpAuthURL"></vue-qrcode>
                    </div>
                  </div>
                  <div>
                    <h4 class="headingH4">Or Enter Code Into Your App</h4>
                    <p id="secretKey" class="text_sm mb-1">
                      <span class="headingH4 border-0"> SecretKey: </span>{{ base32Key }}
                    </p>
                  </div>
                  <div>
                    <h4 class="headingH4">Verify Code</h4>
                    <p class="text_sm mb-1">
                      For changing the setting, please verify the authentication
                      code:
                    </p>
                  </div>
                  <div class="mb-3">
                    <Field
                      type="string"
                      name="auth_code"
                      class="form-control"
                      id="auth_code"
                      placeholder="Authentication Code"
                      aria-describedby="emailHelp"
                      :class="{ 'is-invalid': errors.auth_code }"
                    />
                    <div class="invalid-feedback">{{ errors.auth_code }}</div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="editButton text-center">
                  <button id="verifyActivate" class="btn bg_blue text-white px-4 m-2">
                    <span
                      v-show="isSubmitting"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>
                    Verify & Activate
                  </button>
                  <button
                    class="btn btn-grey px-4 m-2"
                    @click="cancel2FAModal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- modal add user end -->
</template>

<style>
.headingH4 {
  color: #071d44;
  font-size: 1rem !important;
  border-bottom: 1px solid rgb(189, 183, 183);
  padding-bottom: 0.2rem;
}
.orderdList {
  list-style-type: decimal;
}
.text_sm {
  font-size: 0.9rem;
  font-weight: 500;
}
</style>
