
<script setup>
import { Form, Field } from "vee-validate";
import { computed , ref , onMounted , watch} from "vue";
import { useAuthStore } from "../stores/auth.store";
import { useBettorStore } from "../../src/stores/bettor.store";
import * as Yup from "yup";

const authStore = useAuthStore();
const bettorStore = useBettorStore(); 
const showUpdatePassword = computed(() => authStore.$state?.show_update_password);
const password = ref("");
const confirm_password = ref("");
const showUpdatePasswordModal = ref((false));
const schema = Yup.object().shape({
  password: Yup.string().required("New Password is required").matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?=.*\d).+$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit and one symbol"
  ).min(8, "Password must be at least 8 to 64 characters long").max(64),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

async function onSubmit() {
  await bettorStore.updatePassword(password.value, confirm_password.value);
  setTimeout(() => {
    showUpdatePasswordModal.value = false;
  }, 1000);
}
const checkPasswordStatus = () => {
 
  if(showUpdatePassword.value === true && authStore.user.pass_status == 1) {
    showUpdatePasswordModal.value = true;
  } else {
    showUpdatePasswordModal.value = false;
  }
 
}
watch([showUpdatePassword], () => {
  checkPasswordStatus();
});


const passwordCross = () => {
  showUpdatePasswordModal.value = false;
}
</script>
<template>
     <div class="tokenInput" v-if="showUpdatePasswordModal">
      <Form
            @submit="onSubmit"
            id="update-password-form"
            :validation-schema="schema"
            v-slot="{ errors, isSubmitting }"
          >
      <div class="mb-3 py-3 px-5  bg-white rounded-3 updatePassword" >
        <a class="d-inline-flex justify-content-end w-100" id="updatePasswordBtnCross" @click="passwordCross">
          <i class="fa cursorPointer fa-times"></i>
        </a>
        <div class="my-2 d-flex justify-content-center align-items-center">
          <img
            style="max-width: 45px"
            src="/images/only-logo.png"
            class="img-fluid me-1"
            alt=""
          />
          <img
            style="max-width: 115px"
            src="/images/p-logo-new.png"
            class="img-fluid ms-1 me-3"
            alt=""
          />
        </div>
        <div class="mb-3">
        <label for="newpass" class="form-label fw-bold"
          >New Password</label
        >
        
        <Field
                type="password"
                name="password"
                class="form-control"
                v-model="password"
                id="newpass"
                aria-describedby="emailHelp"
                :class="{ 'is-invalid': errors.password }"
              />
              <div class="invalid-feedback">{{ errors.password }}</div>
      </div>
      <div class="mb-3">
        <label for="confpass" class="form-label fw-bold"
          >Confirm Password</label
        >
        <Field
                type="password"
                name="confirm_password"
                class="form-control"
                v-model="confirm_password"
                id="confpass"
                aria-describedby="emailHelp"
                :class="{ 'is-invalid': errors.confirm_password }"
              />
              <div class="invalid-feedback">{{ errors.confirm_password }}</div>
      </div>
        <div class="text-center">
          <button id="updatePassword" class="btn bgProhib" :disabled="isSubmitting">
                <span
                  v-show="isSubmitting"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Update Password
              </button>
        </div>
       
      </div>
    </Form>
  </div>
</template>
<script>
export default {
  name: "UpdatePassword",
};
</script>