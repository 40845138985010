<script setup>
import { onMounted, computed, ref } from "vue";
import { useAuthStore } from "../../stores/auth.store";
import { useAuthTwoFA} from "../../stores/authTwoFA.store"
import { useBettorStore } from "../../stores/bettor.store";
import TwoFactorAuth from "@/pages/TwoFactorAuth.vue";
import EditRoleModal from "@/pages/EditRole.vue";
import ConfirmationDialog from "@/components/Modals/Common/ConfirmationModal.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
const authStoreTwoFA = useAuthTwoFA();
const authStore = useAuthStore();
const bettorStore = useBettorStore();
const dialog = createConfirmDialog(ConfirmationDialog);
const clientName = computed(() => authStore.$state.clientName);
const checkOTPStatus = computed(() => authStore.$state.user?.otp_enabled);
const authUserId = computed(() => authStore.$state.user.id);
const userRole = computed(() => authStore.$state.user?.role);
const isAdminOrSuperAdmin = userRole.value === 'admin' || userRole.value === 'superadmin' || userRole.value === 'monitoring_user';
const twoFactorAuthCheck = ref(false);
const adminRole = ref(false);
const showUpdatePasswordModal = ref(false);
const schema = Yup.object().shape({
  password: Yup.string().required("New Password is required").matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?=.*\d).+$/,
    "Password must contain at least one lowercase letter, one uppercase letter, one digit and one symbol"
  ).min(8, "Password must be at least 8 to 64 characters long").max(64),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
function logout() {
  authStore.logout();
}
const openTwoFactorAuth = async () => {
  if (checkOTPStatus.value === 1) {
    if (isAdminOrSuperAdmin) {
      // 2FA is already enabled, and user is admin or superadmin, call the disable API directly
      callDisableAPI();
    }
  } else {
    // 2FA is disabled, allow the user to enable it
    await authStoreTwoFA.otpGenerate(parseInt(authUserId.value));
    twoFactorAuthCheck.value = true;
  }
};
const callDisableAPI = async () => {
  // Check again for role before proceeding
  if (isAdminOrSuperAdmin) {
    // Call your disable API logic here
    const result = await dialog.reveal({
      title: "Are you sure?",
    });
    if (!result.isCanceled) {
      await authStoreTwoFA.otpDisbale(parseInt(authUserId.value));
    }
  }
};
const cancel2FAModal = () => {
  twoFactorAuthCheck.value = false;
};
const cancelModal = () => {
  adminRole.value = false;
}
async function onSubmit(values) {
  const { password, confirm_password } = values;
  await bettorStore.updatePassword(password, confirm_password);
  setTimeout(() => {
    showUpdatePasswordModal.value = false;
  }, 1000);
}
const updatePasswordBtn = () => {
  showUpdatePasswordModal.value =  true;
}
const updateAdminRole = () => {
  adminRole.value = true;
}
const passwordCross = () => {
  showUpdatePasswordModal.value = false;
}
</script>
<template>
  <header class="heade nav_header_section py-2 pe-4" id="header">
    <div class="nav_header_child">
      <router-link to="/" class="nav_logo main_logo">
        <i class="bx bx-layer nav_logo-icon"></i>
        <div class="my-2 d-flex justify-content-center align-items-center">
          <img
            style="max-width: 45px"
            src="images/prohibet-w-logo.png"
            class="img-fluid me-1"
            alt=""
          />
          <img
            style="max-width: 115px"
            src="/images/p-logo-new-light.png"
            class="img-fluid ms-1 me-3"
            alt=""
          />
        </div>
      </router-link>
      <div class="mb-1 ml-3" @click="check">
        <div class="header_toggle" id="menu-toggle">
          <i id="fa_arrow" class="fa fa-arrow-circle-left"></i>
        </div>
      </div>
      <div class="dropdown ms-auto dropdown_AppName me-4">
        <a
          class="text-white fw-bold dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ clientName }}
        </a>

        <ul class="dropdown-menu mt-3 py-1 shadow-lg" aria-labelledby="dropdownMenuLink">
          <li v-if="isAdminOrSuperAdmin || checkOTPStatus == 0">
            <button id="enableDisableMfa" class="dropdown-item mb-0 fw-bolder txt_siteBlue" @click="openTwoFactorAuth()">
              <i class="fa fa-qrcode pe-1" aria-hidden="true"></i>   {{ checkOTPStatus == 0 ? "Enable MFA" : "Disable MFA" }}
            </button>
          </li>
          <li>
            <button id="updatePasswordBtn" class="dropdown-item mb-0 fw-bolder txt_siteBlue" @click="updatePasswordBtn">
              <i class="fa fa-key pe-1" aria-hidden="true"></i> Update Password
            </button>
          </li>
          <li v-if="can('update-admin-role')">
            <button id="updatePasswordBtn" class="dropdown-item mb-0 fw-bolder txt_siteBlue" @click="updateAdminRole">
              <i class="fa fa-pencil pe-1" aria-hidden="true"></i> Update Role
            </button>
          </li>
          <li>
            <router-link class="dropdown-item mb-0 fw-bolder txt_siteBlue" to="/"  @click="logout">
              <i class="fa fa-sign-out pe-1" aria-hidden="true"></i> Logout
          </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <TwoFactorAuth
    :twoFactorAuthCheck="twoFactorAuthCheck"
    @cancel2FAModal="cancel2FAModal"
  ></TwoFactorAuth>
<EditRoleModal 
  :adminRole="adminRole"
  @cancelModal="cancelModal"
></EditRoleModal>
  <div class="tokenInput" v-if="showUpdatePasswordModal">
      <Form
            @submit="onSubmit"
            id="update-password-form"
            :validation-schema="schema"
            v-slot="{ errors, isSubmitting }"
          >
      <div class="mb-3 py-3 px-5  bg-white rounded-3 updatePassword" >
        <a class="d-inline-flex justify-content-end w-100" id="updatePasswordBtnCross" @click="passwordCross">
          <i class="fa cursorPointer fa-times"></i>
        </a>
        <div class="my-2 d-flex justify-content-center align-items-center">
          <img
            style="max-width: 45px"
            src="/images/only-logo.png"
            class="img-fluid me-1"
            alt=""
          />
          <img
            style="max-width: 115px"
            src="/images/p-logo-new.png"
            class="img-fluid ms-1 me-3"
            alt=""
          />
        </div>
        <div class="mb-3">
        <label for="newpass" class="form-label fw-bold"
          >New Password</label
        >
        
        <Field
                type="password"
                name="password"
                class="form-control"
                id="newpass"
                aria-describedby="emailHelp"
                :class="{ 'is-invalid': errors.password }"
              />
              <div class="invalid-feedback">{{ errors.password }}</div>
      </div>
      <div class="mb-3">
        <label for="confpass" class="form-label fw-bold"
          >Confirm Password</label
        >
        <Field
                type="password"
                name="confirm_password"
                class="form-control"
                 id="confpass"
                aria-describedby="emailHelp"
                :class="{ 'is-invalid': errors.confirm_password }"
              />
              <div class="invalid-feedback">{{ errors.confirm_password }}</div>
      </div>
        <div class="text-center">
          <button id="updatePassword" class="btn bgProhib" :disabled="isSubmitting">
                <span
                  v-show="isSubmitting"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Update Password
              </button>
        </div>
       
      </div>
    </Form>
  </div>
</template>

<style scoped></style>
