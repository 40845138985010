<script setup>
    import { Form, Field } from "vee-validate";
    import { defineProps, computed, getCurrentInstance , ref, watch } from "vue";
    import * as Yup from "yup";
    import { useAuthStore } from "../stores/auth.store";
    import { useBettorStore } from "../stores/bettor.store";
    const schema = Yup.object().shape({
        user_role: Yup.string().required("Please Select value"),
    });
    const roles = ref([
      { name: "List Manager", value: "list_manager" },
      { name: "List and Alert Manager", value: "list_alert_manager" },
      { name: "Alert Editor", value: "report_editor" },
      { name: "Alert Viewer", value: "report_viewer" },
    ]);
    const { emit } = getCurrentInstance();
    const authStore = useAuthStore();
    const bettorStore = useBettorStore();
    const isOverlayVisible = ref(false);
    const userRole = computed(() => authStore.$state.user?.role);
    const selectedRole = ref('');
    watch(userRole, (newRole) => {
      selectedRole.value = newRole;
    });
    
    let props = defineProps({
      adminRole: Boolean,
    });
    
    const updateRole = async () => {
      isOverlayVisible.value = true; // Show the overlay
      await bettorStore.updateUserRoleManually(selectedRole.value);
      emit('cancelModal');
      isOverlayVisible.value = false;
    };
    
    const cancelModal = async () => {
      emit('cancelModal');
      selectedRole.value = '';
    };
    </script>
    
    <template>
        <div v-if="isOverlayVisible" class="overlayRole text-white flex-column">
            <div class="spinner-border spinner-border mr-1" role="status">
            </div>
            <p class="sr-ony fw-bold mt-2">Role Updating...</p>
          </div>
      <div class="tokenInput" v-if="adminRole">
        <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <Form
                id="form"
                @submit="updateRole"
                :validation-schema="schema"
                v-slot="{ errors, isSubmitting }"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="">
                      <div class="mb-3">
                        <label for="role" class="form-label headingH4 required fw-bold mb-0">
                          Admin Roles
                        </label>
                        <Field
                          as="select"
                          name="user_role"
                          class="form-control modal-select"
                          :class="{ 'is-invalid': errors.user_role }"
                          id="user_role"
                          v-model="selectedRole"
                        >
                        <option value="" disabled>Select role</option>
                          <option v-for="item in roles" :key="item.value" :value="item.value">
                            {{ item.name }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">{{ errors.user_role }}</div>
                      </div>
                    </div>
                  </div>
    
                  <div class="col-12">
                    <div class="editButton text-center">
                      <button id="verifyActivate" class="btn bg_blue text-white px-4 m-2" type="submit">
                        <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
                        Confirm
                      </button>
                      <button class="btn btn-grey px-4 m-2" @click="cancelModal">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <style>
    .headingH4 {
      color: #071d44;
      font-size: 1rem !important;
      padding-bottom: 0.2rem;
      border-bottom: 0;
    }
    .orderdList {
      list-style-type: decimal;
    }
    .text_sm {
      font-size: 0.9rem;
      font-weight: 500;
    }
    .overlayRole {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.spinner-borderRole {
  width: 3rem;
  height: 3rem;
}
    </style>
    
