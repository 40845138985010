import { defineStore } from "pinia";
import { fetchWrapper } from "./../_helpers/fetch-wrapper";
import { useNotification } from "@kyvg/vue3-notification";
import { useAuthStore } from "./auth.store";
const notification = useNotification();
export const useAuthTwoFA = defineStore({
  id: "authTwoFA",
  state: () => ({
    loading: false,
    otpEncodeString: "",
    otpAuthUrl: "",
  }),
  actions: {
    async otpGenerate(user_id) {
      const res = await fetchWrapper.post("auth/otp/generate", {
        user_id: parseInt(user_id),
      });
      if (res.status) {
        console.log("res true", res.status);
        const authStore = useAuthStore();
        this.otpEncodeString = res.otp_encode_string;
        this.otpAuthUrl = res.otp_auth_url;
        authStore.user.otp_base32 = res.otp_encode_string;
        authStore.user.otp_auth_url = res.otp_auth_url;
        localStorage.setItem("user", JSON.stringify(authStore.user));
        notification.notify({
          title: "Success!",
          text: res.message, // Use the success message from the API response
          type: "success",
        });
      } else {
        console.log("res false", res.status);
        notification.notify({
          title: "Error!",
          text: res.message,
          type: "error",
        });
      }
    },
    async otpVerify(user_id, authentication_token) {
      const res = await fetchWrapper.post("auth/otp/verify", {
        user_id: parseInt(user_id),
        authentication_token: authentication_token,
      });
      if (res.status) {
        console.log("res true", res.status);
        const authStore = useAuthStore();
        authStore.user.otp_verified = Number(res.status);
        authStore.user.otp_enabled = Number(res.status);
        authStore.opt_authenticate = Number(res.status);
        localStorage.setItem("user", JSON.stringify(authStore.user));
        localStorage.setItem(
          "opt_authenticate",
          JSON.stringify(this.opt_authenticate)
        );
        notification.notify({
          title: "Success!",
          text: res.message, // Use the success message from the API response
          type: "success",
        });
      } else {
        console.log("res false", res.status);
        notification.notify({
          title: "Error!",
          text: res.message,
          type: "error",
        });
      }
    },
    async otpValidation(user_id, authentication_token) {
      const res = await fetchWrapper.post("auth/otp/validate", {
        user_id: parseInt(user_id),
        authentication_token: authentication_token,
      });
      if (res.status) {
        const authStore = useAuthStore();
          authStore.opt_authenticate = Number(res.status);
          localStorage.setItem(
            "opt_authenticate",
            JSON.stringify(this.opt_authenticate)
          );
          if (authStore.user.otp_verified && authStore.opt_authenticate) {
            authStore.show_update_password = true;
            localStorage.setItem(
              "show_update_password",
              this.show_update_password
            );
          }
          localStorage.setItem("user", JSON.stringify(authStore.user));
        notification.notify({
          title: "Success!",
          text: res.message, // Use the success message from the API response
          type: "success",
        });
      } else {
        notification.notify({
          title: "Error!",
          text: res.message,
          type: "error",
        });
      }
    },
    async otpDisbale(user_id) {
      const res = await fetchWrapper.post("auth/otp/disable", {
        user_id: user_id,
      });
      if (res.status) {
        const authStore = useAuthStore();
        authStore.user.otp_verified = 0;
        authStore.user.otp_enabled = 0;
        localStorage.setItem("user", JSON.stringify(authStore.user));
        notification.notify({
          title: "Success!",
          text: res.message, // Use the success message from the API response
          type: "success",
        });
      } else {
        notification.notify({
          title: "Error!",
          text: res.message,
          type: "error",
        });
      }
    },
  },
});
