import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import "./assets/css/app.css";
import "./assets/css/easyDT.css";
import Notifications from "@kyvg/vue3-notification";
import Vue3EasyDataTable from "vue3-easy-data-table";
// import "vue3-easy-data-table/dist/style.css";
import * as ConfirmDialog from "vuejs-confirm-dialog";

import { createPinia } from "pinia";
import VueTheMask from 'vue-the-mask'

import { useAuthStore } from "./stores/auth.store";
const pinia = createPinia();
const app = createApp(App);
const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};
app
  .use(router)
  .use(pinia)
  .use(VueTheMask)
  .use(Notifications)
  .use(ConfirmDialog)
  .component("EasyDataTable", Vue3EasyDataTable);

const userPermissions = useAuthStore();
app.mixin({
  methods: {
    can: (key) => userPermissions?.user?.permissions.includes(key),
  },
});
app.mount("#app");
