<script setup>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { onMounted, computed , ref } from "vue";
import { useAuthStore } from "../stores/auth.store";
import { useAuthTwoFA } from "../stores/authTwoFA.store";
import { useRouter } from "vue-router";
const authStore = useAuthStore();
const authStoreTwoFA = useAuthTwoFA();
const router = useRouter();
const authUserId = computed(() => authStore.$state.user.id); 

const schema = Yup.object().shape({
  auth_code: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required("Authentication is required"),
});
function logout() {
  authStore.logout();
}
async function onSubmit(values) {
  values = values.auth_code;
  await authStoreTwoFA.otpValidation(authUserId.value , values ); 
  router.push("/home"); 
}
</script>
<template>
  <div class="container-fluid bg_blue_100">
    <div class="row vh-100 justify-content-center align-items-center">
        
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="text-center">
            <h2 class="text-warning fw-bolder">Welcome Back</h2>
            <p class="text-white">Verify the Authentication Code</p>
        </div>
        <div class="bg-white card p-4">
          <div class="text-center signInText">
            <h2>Multi-Factor Authentication (MFA)</h2>
            <p>Open the two-step verification app on your mobile
                device to get your verification code.
            </p>
          </div>

          <Form
            id="form"
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors, isSubmitting }"
          >
            <div class="mb-3">
              <Field
                      type="string"
                      name="auth_code"
                      class="form-control"
                      id="auth_code"
                      placeholder="Authentication Code"
                      aria-describedby="emailHelp"
                      :class="{ 'is-invalid': errors.auth_code }"
                    />
                    <div class="invalid-feedback">{{ errors.auth_code }}</div>
            </div>
            <div class="text-center">
              <button id="authenticate" class="btn bgProhib">
                <span
                  v-show="isSubmitting"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Authenticate
              </button>
              <div class="mt-2">
                  <router-link  to="/"  @click="logout" id="basicLogout">
                    <span class="my-3 d-block">
                      Back to basic login
                    </span>
                  </router-link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Verify2FA",
};
</script>

<style scoped>
body {
  background-color: #071d44;
}
.signInText {
  color: #071d44;
}
</style>
