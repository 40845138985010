<script setup>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { onMounted, computed , ref } from "vue";
import { useAuthStore } from "../stores/auth.store";
import { useAuthTwoFA} from "../stores/authTwoFA.store"
import { useRouter } from "vue-router";
const authStore = useAuthStore();
const authStoreTwoFA = useAuthTwoFA();
const userRole = computed(() => authStore.$state.user?.role);
const otpEnabled = computed(() => authStore.$state.user?.otp_enabled);
const otpVerified = computed(() => authStore.$state.user?.otp_verified);
const authUserId =computed(() => authStore.$state.user?.id);
const router = useRouter();
const schema = Yup.object().shape({
  username: Yup.string().required("Username is required").matches(/^\S*$/, 'Username cannot contain spaces'),
  password: Yup.string().required("Password is required"),
});

async function onSubmit(values, event) {
  const authStore = useAuthStore();
  const { username, password } = values;

  await authStore.login(username, password);
  if (otpEnabled.value === 1 && otpVerified.value === 1) {
    router.push("/verify2fa");
  } else if (otpEnabled.value === 1 && otpVerified.value === 0) {
    await authStoreTwoFA.otpGenerate(parseInt(authUserId.value));
    router.push("/home");
  } else if (userRole.value === 'report_editor' || userRole.value === 'report_viewer') {
    router.push("/activity");
  } else {
    router.push("/home");
  }
}
</script>
<template>
  <div class="container-fluid bg_blue_100">
    <div class="row vh-100 justify-content-center align-items-center">
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="bg-white card p-4">
          <div class="text-center signInText">
            <div class="mb-2 d-flex justify-content-center align-items-center">
              <img
                style="max-width: 55px"
                src="/images/only-logo.png"
                class="img-fluid me-1"
                alt=""
              />
              <img
                style="max-width: 125px"
                src="/images/p-logo-new.png"
                class="img-fluid ms-1"
                alt=""
              />
            </div>
            <h2>Sign In</h2>
            <p>to access your ProhiBet portal</p>
          </div>

          <Form
            id="form"
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors, isSubmitting }"
          >
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Username</label>
              <Field
                type="string"
                name="username"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                :class="{ 'is-invalid': errors.username }"
              />
              <div class="invalid-feedback">{{ errors.username }}</div>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label"
                >Password</label
              >
              <Field
                type="password"
                name="password"
                class="form-control"
                :class="{ 'is-invalid': errors.password }"
                id="exampleInputPassword1"
              />
              <div class="invalid-feedback">{{ errors.password }}</div>
            </div>
            <div class="text-center">
              <button id="signIn" class="btn bgProhib" :disabled="isSubmitting">
                <span
                  v-show="isSubmitting"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Sign In
              </button>
              <a href="#/" class="d-none my-3">Forgot Password</a>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
};
</script>

<style scoped>
body {
  background-color: #071d44;
}
.signInText {
  color: #071d44;
}
</style>
